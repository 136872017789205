import * as ReactDOM from 'react-dom/client';

import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
} from 'react';

const browserHydrateFunction = () => {
  return (
    element:
      | string
      | number
      | boolean
      | ReactElement<any, string | JSXElementConstructor<any>>
      | Iterable<ReactNode>
      | ReactPortal
      | null
      | undefined,
    container: Element | DocumentFragment,
  ) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export default browserHydrateFunction;
